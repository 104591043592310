:root {
  --bodyBackground: #000015;
  --bgColor: #000015;
  --showSocial: flex;
  --socialPosition: relative;
  --zIndexMenu: 9;
}

body,
html {
  font-family: "Biko";
  font-size: 12px;
  color: #fff;
  cursor: none;
  max-height: -webkit-fill-available !important;
}

body {
  height: 100vh !important;
  background: none;
}

html {
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-attachment: fixed !important;
  background: var(--bodyBackground);
  background-size: cover !important;
}

h1 {
  font-size: 42px;
}

h1 span {
  display: block;
}

h1 span:nth-child(1) {
  font-size: 62px;
  color: #CCCB00;
}

h1 span:nth-child(2) {
  font-size: 42px;
  color: #CCCB00;
}

h1 span:nth-child(3) {
  font-size: 16px;
}

h2 {
  font-size: 32px;
}

p {
 font-size: 16px;
}

a {
  color: #fff;
}

a:visited {
  color: #fff;
}

a:hover {
  color: #fff;
  text-decoration: none;
}

*:focus {
  outline: none;
}

.cursor {
  position: fixed;
  top: -30px;
  left: -30px;
  background-color: white;
  width: 20px;
  height: 20px;
  z-index: 999;
  border-radius: 10px;
  overflow: hidden;
  margin-top: -10px;
  margin-left: -10px;
  pointer-events: none;
  mix-blend-mode: difference;
  pointer-events: none;
}

.card-font {
  font-family: "Cardo";
}

.card-font-italic {
  font-family: "Cardo-Italic";
}

@supports (-webkit-touch-callout: none) {
  .container-splash, .container-splash .container-splash-canvas, 
  .container-splash .splash-canvas, .container-section-home, .container-section-about,
  .container-section-work {
    max-height: -webkit-fill-available !important;
  }
}

// Common               ----------------------------------------------------------------

.green-color {
  color: #CCCB00;
}

.white-color {
  color:#fff;
}


.bg-green-color {
  background-color: #CCCB00;
}

.left-column {
  display: flex;
  flex-shrink: 0;
  width: 100px;
  border-right: 1px solid #fff;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 30px;
}

// Canvas

.container-canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

// Section Splash       ----------------------------------------------------------------

.container-splash {
  background-color: var(--bgColor);
  z-index: 999;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  opacity: 1;
}

.container-splash .container-splash-canvas {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
}

.splash-canvas {
  width: 100vw;
  width: 100vh;
}

// Section Home       ----------------------------------------------------------------

.container-section-home {
  display: flex;
  height: 100vh;
  width: 100vw;
  position: relative;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}

.left-column-logo { 
  width: 20px;
  height: auto;
}

.container-section-home .content-home {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  // background: url(../images/bg-home.png) top left no-repeat;
  // background-size: cover;
}

.container-section-home .content-home .content-home-first {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100px;
  border-bottom: 1px solid #fff;
  padding-left: 40px;
}

.container-section-home .content-home .content-home-first h2 {
  margin-bottom: 0;
}

.container-section-home .content-home .content-home-second {
  display: flex;
  flex-grow: 1;
  flex-direction: row-reverse;
}

.container-section-home .content-home .content-home-second .first {
  display: flex;
  flex-direction: column;
  border-left: 1px solid #fff;
  width: 30%;
}

.container-section-home .content-home .content-home-second .one {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  height: 48%;
  border-bottom: 1px solid #fff;
}

.container-section-home .content-home .content-home-second .two {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.container-section-home .content-home .content-home-second .second {
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  padding: 40px;
}

// Section About       ----------------------------------------------------------------

.container-section-about {
  display: flex;
  height: 100vh;
  width: 100vw;
  position: relative;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}

.container-section-about .content-about {  
  display: flex;
  flex-grow: 1;
}

.container-section-about .content-about .content-about-first { 
  display: flex;
  background-color: rgba(255, 255, 255, 0.2);
  width: 40%;
  border-right: 1px solid #fff;
  padding: 40px;
  flex-direction: column;
}

.container-section-about .content-about .content-about-second { 
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.content-about-second .about-btn {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../images/about-placeholder.png);
  background-size: cover;
}

// Section Work       ----------------------------------------------------------------

.container-section-work {
  display: flex;
  min-height: 50vh;
  width: 100vw;
  position: relative;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}

.content-work {
  display: flex;
  flex-grow: 1;
  flex-direction: row-reverse;
}

.content-work .content-work-first {
  display: flex;
  flex-direction: column;
  width: 30%;
  padding: 40px;
  border-left: 1px solid #fff;
}

.content-work .content-work-second {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  width: 100%;
  height: auto;
}

.content-work .content-work-second div img {
  width: 100%;
  height: auto;
}
.content-work .content-work-second .div1 { 
  grid-area: 1 / 1 / 3 / 3;
}
.content-work .content-work-second .div2 { 
  grid-area: 1 / 3 / 2 / 4; 
}
.content-work .content-work-second .div3 { 
  grid-area: 1 / 4 / 2 / 5; 
}
.content-work .content-work-second .div4 { 
  grid-area: 3 / 1 / 5 / 3; 
}
.content-work .content-work-second .div5 { 
  grid-area: 2 / 3 / 4 / 5; 
}
.content-work .content-work-second .div6 { 
  grid-area: 4 / 3 / 5 / 4; 
}
.content-work .content-work-second .div7 { 
  grid-area: 4 / 4 / 5 / 5; 
}
.content-work .content-work-second .div8 { 
  grid-area: 5 / 1 / 6 / 3; 
}
.content-work .content-work-second .div9 { 
  grid-area: 5 / 3 / 6 / 4; 
}
.content-work .content-work-second .div10 { 
  grid-area: 5 / 4 / 6 / 5; 
}
.content-work .content-work-second div {
  transition: transform 0.1s ease-in-out;
}
.content-work .content-work-second div:hover {
  // transform: scale(1.05);
  // z-index: 9;
}

// Section Quote1       ----------------------------------------------------------------

.container-section-quote1 {
  height: auto;
  width: 100vw;
  position: relative;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  padding: 120px 0;
}

.container-section-quote1 h2 {
  color: #CCCB00;
  font-size: 52px;
}

.container-section-quote1 .quote-icon {

}

.container-section-quote1 .quote-icon img {
  width: 100px;
}

// Section Partners       ----------------------------------------------------------------

.container-section-partners {
  height: auto;
  width: 100vw;
  position: relative;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  padding: 120px 0;
}

.container-section-partners .row {
  align-items: center !important;
}

.container-section-partners .partner-img {
  padding: 0;
  text-align: center;
}

.container-section-partners img {
  padding: 16px 0;
  width: 100%;
}

// Modal Video Reel       ----------------------------------------------------------------

#modal-video {
  position: fixed;
  z-index: 999;
  text-align: center;
  display: none;
}

.closeVideoModal {
  position: absolute;
  top: 40px;
  right: 40px;
}

// Modal Contact      ----------------------------------------------------------------

.container-section-contact {
  height: auto;
  width: 100vw;
  position: relative;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  padding: 120px 0;
}

.container-section-contact h1 {
  margin-bottom: 30px;
}

.container-section-contact p {
  font-size: 25px;
  margin-bottom: 20px;
}

.container-section-contact input[type=text] {
  background: black;
  border: 1px solid #CCCB00;
  border-radius: 10px;
  color: #fff;
  filter: drop-shadow(0 5px 15px rgba(0, 0, 0, .4));
  font-family: CardoItalic;
  font-size: 28px;
  font-style: italic;
  height: 68px;
  margin-bottom: 25px;
  max-width: 680px;
  text-align: center;
  width: 100%;
}

.container-section-contact .thank-you {
  font-size: 44px;
}

.container-section-contact .send-btn {
  background-color: #CCCB00;
  border: 1px solid #000;
  border-radius: 10px;
  display: inline-block;
  filter: drop-shadow(0 5px 15px rgba(0, 0, 0, .4));
  font-family: Biko;
  font-size: 28px;
  margin-bottom: 10px;
  margin-top: 0;
  padding: 10px 20px;
  color: #000;
}

.container-section-contact .send-btn-disabled {
  background-color: rgba(0, 0, 0, 0.6);
  border: 1px solid #CCCB00;
  border-radius: 10px;
  color: #fff;
  cursor: default;
  display: inline-block;
  filter: drop-shadow(0 5px 15px rgba(0, 0, 0, .4));
  font-family: Biko;
  font-size: 28px;
  margin-bottom: 10px;
  margin-top: 0;
  padding: 10px 20px;
  pointer-events: none;
}

// 

@media only screen and (max-width: 1200px) {
  
}

@media only screen and (max-width: 1080px) {
  
}

@media only screen and (max-width: 992px) {
  
}

@media only screen and (max-width: 880px) {
  
}

@media only screen and (max-width: 844px) {

}

@media only screen and (max-width: 768px) {
  
}

@media only screen and (max-width: 430px) {
  
}

@media only screen and (max-width: 390px) {

}

@media (hover: none) {
  .cursor {
     pointer-events: none;
     display: none;
   }
}

@media only screen and (max-height: 800px) and (orientation: landscape) {
  
}

@media only screen and (max-height: 430px) and (orientation: landscape) {
  
}
@font-face {
  font-family: 'Biko';
  src: url('../fonts/BikoRegular.eot');
  src: url('../fonts/BikoRegular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/BikoRegular.woff2') format('woff2'),
       url('../fonts/BikoRegular.woff') format('woff'),
       url('../fonts/BikoRegular.ttf')  format('truetype'),
       url('../fonts/BikoRegular.svg#Biko') format('svg');
}

@font-face {
  font-family: 'Biko-Bold';
  src: url('../fonts/BikoBold.eot');
  src: url('../fonts/BikoBold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/BikoBold.woff2') format('woff2'),
       url('../fonts/BikoBold.woff') format('woff'),
       url('../fonts/BikoBold.ttf')  format('truetype'),
       url('../fonts/BikoBold.svg#Biko') format('svg');
}

@font-face {
  font-family: 'Cardo';
  src: url('../fonts/Cardo-Regular.eot');
  src: url('../fonts/Cardo-Regular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Cardo-Regular.woff2') format('woff2'),
       url('../fonts/Cardo-Regular.woff') format('woff'),
       url('../fonts/Cardo-Regular.ttf')  format('truetype'),
       url('../fonts/Cardo-Regular.svg#Cardo') format('svg');
}

@font-face {
  font-family: 'Cardo-Italic';
  src: url('../fonts/Cardo-Italic.eot');
  src: url('../fonts/Cardo-Italic.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Cardo-Italic.woff2') format('woff2'),
       url('../fonts/Cardo-Italic.woff') format('woff'),
       url('../fonts/Cardo-Italic.ttf')  format('truetype'),
       url('../fonts/Cardo-Italic.svg#Cardo') format('svg');
}